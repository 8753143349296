import { useState } from 'react';
import './App.css';

import classnames from 'classnames'

import {useTranslation} from "react-i18next";

// import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

// import { FaUsers, FaListUl } from 'react-icons/fa';
// import { IoDocuments } from 'react-icons/io5';
// import { SlGraph } from 'react-icons/sl'

function App() {
  const {t, i18n} = useTranslation('common');

  const [activeLanguage, setActiveLanguage] = useState('en')

  const toggleLanguage = (language) => {
    i18n.changeLanguage(language);
    setActiveLanguage(language)
  }

  return (
    <div className="App">
      <section id='heroSection'>
        <header>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <img
              alt="logo"
              src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686676447/Gazindo%20Lite/logoPrimary.png"
              style={{
                height: 25,
              }}
              className='hideOnMobile'
            />
            <img
              alt="logo"
              src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1686679610/Gazindo%20Lite/logo.png"
              style={{
                height: 35,
              }}
              className='hideOnDesktop'
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className='navLanguageToggle'>
              <p onClick={() => toggleLanguage('en')} style={{paddingRight: '0.25rem', borderRight: '1px solid white'}} className={classnames(
                {
                  active: activeLanguage === 'en'
                }
              )}>
                EN
              </p>
              <p onClick={() => toggleLanguage('es')} style={{paddingLeft: '0.25rem', paddingRight: '0.25rem', borderRight: '1px solid white'}} className={classnames(
                {
                  active: activeLanguage === 'es'
                }
              )}>
                ES
              </p>
              <p onClick={() => toggleLanguage('de')} style={{paddingLeft: '0.25rem'}} className={classnames(
                {
                  active: activeLanguage === 'de'
                }
              )}>
                DE
              </p>
            </div>
            <button onClick={() => window.location.href="https://app.gazindolite.com/login"} className='primaryBtn'>
              {t('login')}
            </button>
          </div>
        </header>
        <div id='heroBanner'>
          <h1>
            {t('takeControl')}.
          </h1>
          <p>
            {t('heroSubtext')}
          </p>
          <button onClick={() => window.location.href="https://app.gazindolite.com/register"} className='secondaryBtn'>
            {t('createYourAccount')}
          </button>
        </div>
        {/* <div id='featuresSection'>
          <div className='card'>
            <FaUsers />
            <div>
              <p className='cardHeader'>
                {t('accessControlHeader')}
              </p>
              <p className='cardText'>
                {t('accessControlText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <FaListUl />
            <div>
              <p className='cardHeader'>
                {t('sharedListsHeader')}
              </p>
              <p className='cardText'>
                {t('sharedListsText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <SlGraph />
            <div>
              <p className='cardHeader'>
                {t('analyticsHeader')}
              </p>
              <p className='cardText'>
                {t('analyticsText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <IoDocuments />
            <div>
              <p className='cardHeader'>
                {t('recordHeader')}
              </p>
              <p className='cardText'>
                {t('recordText')}
              </p>
            </div>
          </div>
        </div> */}
        <footer>
          <p>© 2021 Gazindo UG, all rights reserved</p>
        </footer>
        {/* <div id='featuresSection'>
          <div className='card'>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <FaUsers />
            </div>
            <div>
              <p className='cardHeader'>
                {t('accessControlHeader')}
              </p>
              <p className='cardText'>
                {t('accessControlText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <FaListUl />
            </div>
            <div>
              <p className='cardHeader'>
                {t('sharedListsHeader')}
              </p>
              <p className='cardText'>
                {t('sharedListsText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <SlGraph />
            </div>
            <div>
              <p className='cardHeader'>
                {t('analyticsHeader')}
              </p>
              <p className='cardText'>
                {t('analyticsText')}
              </p>
            </div>
          </div>
          <div className='card'>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <IoDocuments />
            </div>
            <div>
              <p className='cardHeader'>
                {t('recordHeader')}
              </p>
              <p className='cardText'>
                {t('recordText')}
              </p>
            </div>
          </div> */}
        {/* </div> */}
      </section>
    </div>
  );
}

export default App;
